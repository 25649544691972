import { createRouter, createWebHashHistory, RouteRecordRaw } from "vue-router";
import {showFailToast} from 'vant'

const routes: Array<RouteRecordRaw> = [
    {
        path: "/",
        component: () => import("../views/layout/index.vue"),
        children: [
            {
                path: "",
                name: "home",
                component: () => import("../views/layout/home/index.vue"),
            },
            {
                path: "/about",
                name: "about",
                component: () => import("../views/layout/about/index.vue"),
            },
            {
                path: "/system",
                name: "system",
                component: () => import("../views/layout/system/index.vue"),
            },
            {
                path: "/recharge",//充值金额
                name: "recharge",
                meta:{
                    requireAuth:true
                },
                component: () => import("../views/layout/recharge/index.vue"),
            },
            {
                path: "/refund",//申请退款
                meta:{
                    requireAuth:true
                },
                name: "refund",
                component: () => import("../views/layout/refund/index.vue"),
            },
            {
                path: "/song",//歌曲列表
                meta:{
                    requireAuth:true
                },
                name: "song",
                component: () => import("../views/layout/song/index.vue"),
            },
            {
                path: "/secure",//密码安全
                meta:{
                    requireAuth:true
                },
                name: "secure",
                component: () => import("../views/layout/secure/index.vue"),
            },
            {
                path: "/agreement",
                name: "agreement",
                component: () => import("../views/layout/agreement/index.vue"),
            },
            {
                path: "/privacy",
                name: "privacy",
                component: () => import("../views/layout/privacy/index.vue"),
            },
            {
                path: "/minority",
                name: "minority",
                component: () => import("../views/layout/minority/index.vue"),
            },
            {
                path: "/dispute",
                name: "dispute",
                component: () => import("../views/layout/dispute/index.vue"),
            },
        ],
    },
    
    {
        path: "/login",
        name: "login",
        component: () => import("../views/login/index.vue"),
    },
    {
        path: "/register",
        name: "register",
        component: () => import("../views/register/index.vue"),
    }
];

const router = createRouter({
    history: createWebHashHistory(process.env.BASE_URL),
    routes,
});

router.beforeEach(((to, from, next) => {
    if (to.meta.requireAuth){
        if (window.localStorage.getItem('user')){
            next()
        }else{
            showFailToast("未登录")
            next({path:'/login'})
        }
    }
    next()
}))
export default router;
