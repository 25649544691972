import { createApp } from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
// Toast
import { showToast } from "vant";
import "vant/es/toast/style";

// Dialog
import { showDialog } from "vant";
import "vant/es/dialog/style";

// Notify
import { showNotify } from "vant";
import "vant/es/notify/style";

// ImagePreview
import { showImagePreview } from "vant";
import "vant/es/image-preview/style";

createApp(App).use(store).use(router).mount("#app");
